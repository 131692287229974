import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route, useHref } from 'react-router-dom';

import './arrivals.css';

import Card from '../card/Card';
import TechnoView from '../../Pages/TechnoView';

//import filejson from '../card/mock1.json';
//const src = "http://127.0.0.1:2909/api/getJSON"
//const src = "http://89.108.99.73:2909/api/getJSON"



function Arrivals () {

const [posts, setPosts] = useState([]);

useEffect(() => {
    const fetchData = async () => {
        const result = await axios(
            'http://89.108.99.73/api/getJSON',
        );
        setPosts(result.data);
//        console.log(result.data);
    };
    fetchData();
}, []);

let item = posts[0];
//console.log(posts);
//console.log(item);
//console.log(Object.keys(item).length);


let DisplayData = '';
(item) ? (DisplayData=Object.values(item).map(
    (val)=>{
//        console.log(val);
        return(            
            <Card parametr={val} />
        )}
)
) : (console.log());


    return (

        <section className='arrivals'>
            <div >
                <div className='contentArrivals'>

                    {DisplayData}

                </div>
            </div>
        </section>

    );
}


export default Arrivals;


/*
                {console.log(item.Card1)}

                console.log(key, value);
                console.log(item.key);

                массив вручную из файла:
                <Card parametr={filejson.Card1} />
                <Card parametr={filejson.Card2} />
                <Card parametr={filejson.Card3} />
                <Card parametr={filejson.Card4} />
                <Card parametr={filejson.Card5} />
                <Card parametr={filejson.Card6} />
                <Card parametr={filejson.Card7} />


                {console.log(item.Card1)}

                массив вручную с сервера:
                <Card parametr={item.Card1} />
                <Card parametr={item.Card2} />
                <Card parametr={item.Card3} />
                <Card parametr={item.Card4} />
                <Card parametr={item.Card5} />
                <Card parametr={item.Card6} />
                <Card parametr={item.Card7} />

                название карточки:
                {console.log(`Card${1}`)}

                вывод массива:
                {console.log(Object.values(item))}

                печать всех объектов. вариант1:
                {Object.values(item).forEach(val => console.log(val))}
*/



//fetch('http://89.108.99.73:2909/api/getJSON', {
//    method: 'GET', 
//    mode: 'no-cors'
//}).then((response) => {console.log(response.data);});
//    .then((response) => filejson = response.json()

// let filejsonNew

//    const [articles, setArticles] = useState([]);

    /*
    useEffect(() => {
        axios
            .get(src).then(response => {
//            console.log(response.data);
                filejsonNew = response.data.Card1;
//                console.log(filejsonNew);
                setArticles(response.data.results);
                
        }).catch((err) => 
            {
                console.log("Failed: " + err);
            });
    }, []);
*/