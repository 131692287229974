// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 576px) {
    .paddingService {
        padding-top: 222px;
    }
}

@media screen and (max-width: 576px) {
    .paddingService {
        padding-top: 188px;
    }
}

.contentService {
    display: flex;
    flex-direction: column;
    flex-wrap: inherit;
    justify-content: left;
    width: 100%;
    max-width: 1140px;
    margin: 2% auto 140px;
}

.headService {
    font-size: 40px;
    padding-bottom: 36px;
    font-weight: 100;
    padding-left: 12px;
    padding-right: 12px;
}

.textService {
    padding-bottom: 20px;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/service.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":["@media screen and (min-width: 576px) {\r\n    .paddingService {\r\n        padding-top: 222px;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 576px) {\r\n    .paddingService {\r\n        padding-top: 188px;\r\n    }\r\n}\r\n\r\n.contentService {\r\n    display: flex;\r\n    flex-direction: column;\r\n    flex-wrap: inherit;\r\n    justify-content: left;\r\n    width: 100%;\r\n    max-width: 1140px;\r\n    margin: 2% auto 140px;\r\n}\r\n\r\n.headService {\r\n    font-size: 40px;\r\n    padding-bottom: 36px;\r\n    font-weight: 100;\r\n    padding-left: 12px;\r\n    padding-right: 12px;\r\n}\r\n\r\n.textService {\r\n    padding-bottom: 20px;\r\n    font-size: 16px;\r\n    padding-left: 12px;\r\n    padding-right: 12px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
