// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer__back {
    background: #fff;
    border-top: solid 1px #EAEBEC;
}

.footer__number {
    color: #000;
    font-size: large;
}

.footer__text {
    color: #93A3BC;
    width: 240px;
}`, "",{"version":3,"sources":["webpack://./src/Components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,YAAY;AAChB","sourcesContent":[".footer__back {\r\n    background: #fff;\r\n    border-top: solid 1px #EAEBEC;\r\n}\r\n\r\n.footer__number {\r\n    color: #000;\r\n    font-size: large;\r\n}\r\n\r\n.footer__text {\r\n    color: #93A3BC;\r\n    width: 240px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
