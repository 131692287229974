import React, { Component } from 'react';
import { useParams } from "react-router-dom"

import ArrivalsSearch from '../Components/arrivals/ArrivalsSearch';
import './technoSearch.css'


export default function TechnoSearch ( {parametr} ) {
//    render() {

const { itemId } = useParams();


        return (
            <>
            {
                itemId ? (
            <div >

                <div className='textSearch'>
                    Результаты поиска по запросу: {parametr.slice(28)}
                    {itemId}
                </div>
                
                <ArrivalsSearch parametr={parametr} />
                
            </div>
                ) : (
                    <div className='mt-test'>
                        <p>Page item is not present</p>
                    </div>
                )
            }
            </>
        );
//    }
}