import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap'

import "./formasInfo.css"


function FormasInfo( {setActive} ) {
    return (
        <div className='container_template_Info'>

            <div className='ContentForm'>Ваш запрос отправлен!</div>
            <div className='ContentFormComment'>Наш менеджер свяжется с Вами в ближайщее время.</div>

            <div className='position_buttonClose'>
                    <Button 
                        variant='warning'
                        onClick={() => setActive(false)} >
                        Закрыть 
                    </Button>
            </div>
        
        </div>
    );

}

export default FormasInfo;
