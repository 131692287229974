import React, { Component } from 'react';

import './service.css';


export default class Service extends Component {
    render() {
        return (
            <div className='paddingService'>
                <div className='contentService'>
                    <div className='headService'>
                        Мы предлагаем следующие услуги
                    </div>

                    <div>
                        <div className='textService'> 1. Поиск подходящей научной технологии для Вашего бизнеса </div>
                        <div className='textService'> 2. Консультация и внедрение научной технологии в Ваши бизнес-процессы </div>
                        <div className='textService'> 3. Юридическое сопровождение при оформлении договора на использование интеллектуальной собственности </div>
                        <div className='textService'>  </div>
                        <div className='textService'>  </div>
                        <div className='textService'>  </div>
                        <div className='textService'>  </div>
                    </div>
                </div>
            </div>
        );
    }
}