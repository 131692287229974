// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__l8CWH {
    width: 360px;
    background: radial-gradient(210% 260% at 25% -15%, #fff 0, #fff0 100%), rgba(147, 163, 188, 0.4);
    border-radius: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
    padding: 20px 20px 20px 20px;
    cursor: pointer;
}

.Card_cardInside__ubtEU {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
}

.Card_card__l8CWH:hover {
    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
}


.Card_card__title__X-NMH {
    height: 96px;
    font-size: 32px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}


.Card_card_short_text__hhbws {
    height: 48px;
    font-size: 16px;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.Card_card__text__rJqkr {
    font-size: 16px;
}

.Card_card__info__EPxoc {
    display: inline-flex;
    width: 100%;
}

.Card_card__place__aWIcv {
    display: inline-flex;
    width: 100%;
    justify-content: right;
}

.Card_btnContact__Udy2B {
    display: inline-flex;
    width: 100%;
    justify-content: right;
}



`, "",{"version":3,"sources":["webpack://./src/Components/card/Card.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gGAAgG;IAChG,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,qBAAqB;IACrB,SAAS;IACT,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,qBAAqB;IACrB,SAAS;AACb;;AAEA;IACI,6CAA6C;AACjD;;;AAGA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,oBAAoB;IACpB,qBAAqB;IACrB,4BAA4B;AAChC;;;AAGA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,oBAAoB;IACpB,qBAAqB;IACrB,4BAA4B;AAChC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,sBAAsB;AAC1B","sourcesContent":[".card {\r\n    width: 360px;\r\n    background: radial-gradient(210% 260% at 25% -15%, #fff 0, #fff0 100%), rgba(147, 163, 188, 0.4);\r\n    border-radius: 16px;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: left;\r\n    gap: 20px;\r\n    padding: 20px 20px 20px 20px;\r\n    cursor: pointer;\r\n}\r\n\r\n.cardInside {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: left;\r\n    gap: 20px;\r\n}\r\n\r\n.card:hover {\r\n    box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);\r\n}\r\n\r\n\r\n.card__title {\r\n    height: 96px;\r\n    font-size: 32px;\r\n    overflow: hidden;\r\n    width: 100%;\r\n    display: -webkit-box;\r\n    -webkit-line-clamp: 2;\r\n    -webkit-box-orient: vertical;\r\n}\r\n\r\n\r\n.card_short_text {\r\n    height: 48px;\r\n    font-size: 16px;\r\n    overflow: hidden;\r\n    width: 100%;\r\n    display: -webkit-box;\r\n    -webkit-line-clamp: 2;\r\n    -webkit-box-orient: vertical;\r\n}\r\n\r\n.card__text {\r\n    font-size: 16px;\r\n}\r\n\r\n.card__info {\r\n    display: inline-flex;\r\n    width: 100%;\r\n}\r\n\r\n.card__place {\r\n    display: inline-flex;\r\n    width: 100%;\r\n    justify-content: right;\r\n}\r\n\r\n.btnContact {\r\n    display: inline-flex;\r\n    width: 100%;\r\n    justify-content: right;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__l8CWH`,
	"cardInside": `Card_cardInside__ubtEU`,
	"card__title": `Card_card__title__X-NMH`,
	"card_short_text": `Card_card_short_text__hhbws`,
	"card__text": `Card_card__text__rJqkr`,
	"card__info": `Card_card__info__EPxoc`,
	"card__place": `Card_card__place__aWIcv`,
	"btnContact": `Card_btnContact__Udy2B`
};
export default ___CSS_LOADER_EXPORT___;
