// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modal__content {
    border-radius: 12px;
    background-color: white;
    transform: scale(0.5);
    transition: 0.4s all;
}

.modal__content.active {
    transform: scale(1);
}`, "",{"version":3,"sources":["webpack://./src/Components/dialog/modal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,iCAAiC;IACjC,eAAe;IACf,MAAM;IACN,OAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,uBAAuB;IACvB,qBAAqB;IACrB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".modal {\r\n    height: 100vh;\r\n    width: 100vw;\r\n    background-color: rgba(0,0,0,0.4);\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    opacity: 0;\r\n    pointer-events: none;\r\n    transition: 0.5s;\r\n}\r\n\r\n.modal.active {\r\n    opacity: 1;\r\n    pointer-events: all;\r\n}\r\n\r\n.modal__content {\r\n    border-radius: 12px;\r\n    background-color: white;\r\n    transform: scale(0.5);\r\n    transition: 0.4s all;\r\n}\r\n\r\n.modal__content.active {\r\n    transform: scale(1);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
