// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 576px) {
    .paddingAbout {
        padding-top: 248px;
    }
}

@media screen and (max-width: 576px) {
    .paddingAbout {
        padding-top: 198px;
    }
}

.contentAbout {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    row-gap: 40px;
    column-gap: 85px;
    width: 100%;
    max-width: 1140px;
    margin: 2% auto 140px;
}

.textAbout {
    min-width: 350px;
    max-width: 680px;
}

.techno_image_About {
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/about.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI,aAAa;IACb,eAAe;IACf,qBAAqB;IACrB,aAAa;IACb,gBAAgB;IAChB,WAAW;IACX,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["@media screen and (min-width: 576px) {\r\n    .paddingAbout {\r\n        padding-top: 248px;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 576px) {\r\n    .paddingAbout {\r\n        padding-top: 198px;\r\n    }\r\n}\r\n\r\n.contentAbout {\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    justify-content: left;\r\n    row-gap: 40px;\r\n    column-gap: 85px;\r\n    width: 100%;\r\n    max-width: 1140px;\r\n    margin: 2% auto 140px;\r\n}\r\n\r\n.textAbout {\r\n    min-width: 350px;\r\n    max-width: 680px;\r\n}\r\n\r\n.techno_image_About {\r\n    border-radius: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
