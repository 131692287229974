// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.btn-info {
    background-color: #6D8978;
    color: #fff;
    border-radius: 100px;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
}

.btn-info:hover {
    background-color: #829C8C;
    color: #fff;
}

.btn-info:active {
    background-color: #4A5E52 !important;
    color: #fff !important;
}

.btn-info:focus-visible {
    background-color: #6D8978 !important;
    color: #fff !important;
}

a {
    text-decoration: none;
    color: unset;
}`, "",{"version":3,"sources":["webpack://./src/Components/card/card.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,WAAW;IACX,oBAAoB;IACpB,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":["\r\n.btn-info {\r\n    background-color: #6D8978;\r\n    color: #fff;\r\n    border-radius: 100px;\r\n    height: 38px;\r\n    padding-left: 20px;\r\n    padding-right: 20px;\r\n    border: none;\r\n}\r\n\r\n.btn-info:hover {\r\n    background-color: #829C8C;\r\n    color: #fff;\r\n}\r\n\r\n.btn-info:active {\r\n    background-color: #4A5E52 !important;\r\n    color: #fff !important;\r\n}\r\n\r\n.btn-info:focus-visible {\r\n    background-color: #6D8978 !important;\r\n    color: #fff !important;\r\n}\r\n\r\na {\r\n    text-decoration: none;\r\n    color: unset;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
