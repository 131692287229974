// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_template_Info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
    width: 40vw;
}

.ContentForm {
    display: flex;
    justify-content: center;
    font-size: x-large;
    text-align: center;
    padding-bottom: 16px;
}

.ContentFormComment {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 32px;
}

.position_buttonClose {
    display: flex;
    justify-content: center;  
}`, "",{"version":3,"sources":["webpack://./src/Components/dialog/formasInfo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".container_template_Info {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    padding-left: 60px;\r\n    padding-right: 60px;\r\n    padding-top: 60px;\r\n    padding-bottom: 60px;\r\n    width: 40vw;\r\n}\r\n\r\n.ContentForm {\r\n    display: flex;\r\n    justify-content: center;\r\n    font-size: x-large;\r\n    text-align: center;\r\n    padding-bottom: 16px;\r\n}\r\n\r\n.ContentFormComment {\r\n    display: flex;\r\n    justify-content: center;\r\n    text-align: center;\r\n    padding-bottom: 32px;\r\n}\r\n\r\n.position_buttonClose {\r\n    display: flex;\r\n    justify-content: center;  \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
