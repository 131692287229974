import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap'

import IntlTelInput from 'intl-tel-input/react'

import "./formas.css"


function Formas( {setActive, parametrId} ) {
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]:value
        })

        if(!!errors[field])
        setErrors({
            ...errors,
            [field]:null
        })
    }

    const validateForm = ()=>{
        const{ fio, email, phone, check, id } = form
        const newErrors = {}
        const rePhone = /^[a-zа-яё]/
        const reEmail = /@/

        if(!fio || fio === '') newErrors.fio = 'Введите Ваши Фамилию Имя Отчество'
        if(!email || email === '') newErrors.email = 'Введите email'
            else if(!reEmail.test(email)) newErrors.email = 'Введите корректный email'
        if(!phone || phone === '') newErrors.phone = 'Введите телефон'
            else if(rePhone.test(phone)) newErrors.phone = 'Введите корректный телефон'
        if(!check) newErrors.check = 'Необходимо согласиться'

        return newErrors
    }

    const handleSubmit = e => {
        e.preventDefault()

        const formErrors = validateForm()
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }
        else{
            form.id=parametrId
        //    console.log('form submitted')
            console.log(form)
            setActive(false)
        }
        
    }


    return (
        <Container>

            <Form>

            <div className='btnClosePosition'>
                    <Button 
                        variant='outline-info' 
                        type='reset'
                        onClick={() => setActive(false)}>
                            <svg className='iconBtnClose' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">                                    
                                <path d="M3.35938 20.4675L20.6394 3.1875" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M3.35938 3.1875L20.6394 20.4675" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                    </Button>
                </div>
                
                <div className='container_template'>

                    <Form.Group controlId='fio'>
                        <Form.FloatingLabel
                            controlId="floatingInput"
                            label="ФИО"
                        >
                            <Form.Control 
                                type="text" 
                                placeholder="Введите ФИО"
                                className={!!errors.fio && 'red-border'}
                                required
                                value={form.fio}
                                isInvalid={!!errors.fio}
                                onChange={(e) => setField('fio', e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid"> {errors.fio} </Form.Control.Feedback>
                        </Form.FloatingLabel>  
                    </Form.Group>


                    <Form.Group controlId='phone'>
                        <Form.FloatingLabel
                            controlId="floatingInput"
                            label="Телефон"
                        >
                            <Form.Control 
                                type="tel" 
                                placeholder="Введите номер телефона"
                                pattern="[0-9]{10}"
                                className={!!errors.email && 'red-border'}
                                maxLength={12}
                                required
                                value={form.phone}
                                isInvalid={!!errors.phone}
                                onChange={(e) => setField('phone', e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid"> {errors.phone} </Form.Control.Feedback>
                        </Form.FloatingLabel>  
                    </Form.Group>



                    <Form.Group controlId='email'>
                        <Form.FloatingLabel
                            controlId="floatingInput"
                            label="e-mail"
                        >
                            <Form.Control 
                                type="email" 
                                placeholder="Введите e-mail"
                                className={!!errors.email && 'red-border'}
                                required
                                value={form.email}
                                isInvalid={!!errors.email}
                                onChange={(e) => setField('email', e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid"> {errors.email} </Form.Control.Feedback>
                        </Form.FloatingLabel>  
                    </Form.Group>


                    <Form.Group controlId='formBasicCheckbox'>
                        <Form.Check 
                            type='checkbox' 
                            label='Я согласен(а) на обработку персональных данных' 
                            className={!!errors.check && 'red-border'}
                            required
                            value={form.check}
                            isInvalid={!!errors.check}
                            onChange={(e) => setField('check', e.target.checked)}
                        > 
                        </Form.Check>
                        <Form.Control.Feedback type="invalid"> {errors.check} </Form.Control.Feedback>
                    </Form.Group>

                </div>


                <div className='position_button'>
                    <Button variant='warning' 
                            type='submit' 
                            onClick={ handleSubmit }> 
                        Отправить 
                    </Button>
                </div>
                

            </Form>
        </Container>

    );
}


export default Formas;





/*
                    <Form.Group controlId='formBasicPassword'>
                        <Form.Label>Example textarea</Form.Label>
                        <Form.Control as='textarea' rows='3' />
                    </Form.Group>
*/