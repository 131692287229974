import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

import './footer.css'


export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
            <Container fluid className='footer__back'>
                <Container style={{ justifyContent: 'left', padding: '20px'}}>
                <div className='footer__number'>8 (901) 050-50-01</div>
                <div className='footer__text'>ООО "Компания "Современные информационные бизнес-технологии"</div>
                </Container>
            </Container>
            </div>
        );
    }
}

/*footer fixed-bottom*/