import React, { useState } from 'react';

import { FormControl, Container, Form, Button } from 'react-bootstrap';

import { BrowserRouter as Router, Routes, Route, useHref, BrowserRouter, Link } from 'react-router-dom';

import styles from './Card.module.css';
import './card.css';

import Cardicon01 from './mark-location-svgrepo-com.svg';
import Modal from '../dialog/Modal';
import Formas from '../dialog/Formas';
import FormasInfo from '../dialog/FormasInfo';


function Card( {parametr} ) {

    const [modalActive, setModalActive] = useState(false)
    
    const handleClick = (  ) => {
        console.log( link ) 
    }

    let link = useHref( `/technoview/${parametr.id}` )


    return (
        <>
            <div 
                className={styles.card} 
            //    onClick={ ( ) => { handleClick( {parametr} ) } } 
            >

                <Link to={ link } className={styles.cardInside}>
                    <div className={styles.card__title}>
                        {parametr.main_name}
                    </div>

                    <div className={styles.card__info}>
                        <div>
                            <div className={styles.card__text}> {parametr.author} </div>
                            <div className={styles.card__text}> {parametr.university} </div>
                        </div>
                    </div>

                    <div className={styles.card__place}>
                            <img className={styles.card__img}
                                src={Cardicon01}
                                alt='Name ...'
                                height='24'
                                width='24'
                            />
                            <div className={styles.card__text}> {parametr.city} </div>
                        </div>

                    <div className={styles.card_short_text}>
                        {parametr.short_text}
                    </div>
                </Link>

                <div className={styles.btnContact}>
                    <Button 
                        variant='info' 
                        onClick={() => setModalActive(true)}
                    >Связаться</Button>
                </div>

                <Modal active={modalActive} setActive={setModalActive}>
                    <Formas setActive={setModalActive} parametrId={parametr.id} />
                </Modal>

            </div>

        </>

    );
}

export default Card;



/*
    <Link to={`/technoview/${printValue.id}`}></Link>

    console.log( parametr );

*/