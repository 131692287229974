// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 576px) {
    .textSearch {
        padding-left: 74px;
        padding-right: 74px;
        padding-top: 196px;
        font-style: italic;
    }
}

@media screen and (max-width: 576px) {
    .textSearch {
        padding-left: 36px;
        padding-right: 36px;
        padding-top: 208px;
        font-style: italic;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/technoSearch.css"],"names":[],"mappings":"AAAA;IACI;QACI,kBAAkB;QAClB,mBAAmB;QACnB,kBAAkB;QAClB,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,mBAAmB;QACnB,kBAAkB;QAClB,kBAAkB;IACtB;AACJ","sourcesContent":["@media screen and (min-width: 576px) {\r\n    .textSearch {\r\n        padding-left: 74px;\r\n        padding-right: 74px;\r\n        padding-top: 196px;\r\n        font-style: italic;\r\n    }\r\n}\r\n\r\n@media screen and (max-width: 576px) {\r\n    .textSearch {\r\n        padding-left: 36px;\r\n        padding-right: 36px;\r\n        padding-top: 208px;\r\n        font-style: italic;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
