import React, { Component } from 'react';

import Arrivals from '../Components/arrivals/Arrivals';


export default class Techno extends Component {
    render() {
        return (
            <div className='mt-test'>
                
                <Arrivals />
                
            </div>
        );
    }
}