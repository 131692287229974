import React, {  } from "react";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './Components/header/Header';
import Footer from './Components/footer/Footer';


function App() {

  return (
    <div>
        <Header />

        <Footer />

    </div>
  );
}

export default App;
