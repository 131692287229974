// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width: 576px) {
    .container_template {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 24px;
        padding-bottom: 42px;
        
    }
}

@media screen and (max-width: 576px) {
    .container_template {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 24px;
        padding-bottom: 42px;
        
    }
}



.position_button {
    display: flex;
    justify-content: center;
    padding-bottom: 42px;    
}

.form-floating {
    padding-bottom: 20px;
}

.btnClosePosition {
    text-align: right;
    padding-top: 24px;
}

.iconBtnClose {
    width: 24px;
    height: 24px;
    stroke: #99A2AD;
}

.iconBtnClose:hover {
    stroke: #3A7AC4;
}`, "",{"version":3,"sources":["webpack://./src/Components/dialog/formas.css"],"names":[],"mappings":"AAAA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,uBAAuB;QACvB,kBAAkB;QAClB,mBAAmB;QACnB,iBAAiB;QACjB,oBAAoB;;IAExB;AACJ;;AAEA;IACI;QACI,aAAa;QACb,sBAAsB;QACtB,uBAAuB;QACvB,kBAAkB;QAClB,mBAAmB;QACnB,iBAAiB;QACjB,oBAAoB;;IAExB;AACJ;;;;AAIA;IACI,aAAa;IACb,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["@media screen and (min-width: 576px) {\r\n    .container_template {\r\n        display: flex;\r\n        flex-direction: column;\r\n        justify-content: center;\r\n        padding-left: 60px;\r\n        padding-right: 60px;\r\n        padding-top: 24px;\r\n        padding-bottom: 42px;\r\n        \r\n    }\r\n}\r\n\r\n@media screen and (max-width: 576px) {\r\n    .container_template {\r\n        display: flex;\r\n        flex-direction: column;\r\n        justify-content: center;\r\n        padding-left: 24px;\r\n        padding-right: 24px;\r\n        padding-top: 24px;\r\n        padding-bottom: 42px;\r\n        \r\n    }\r\n}\r\n\r\n\r\n\r\n.position_button {\r\n    display: flex;\r\n    justify-content: center;\r\n    padding-bottom: 42px;    \r\n}\r\n\r\n.form-floating {\r\n    padding-bottom: 20px;\r\n}\r\n\r\n.btnClosePosition {\r\n    text-align: right;\r\n    padding-top: 24px;\r\n}\r\n\r\n.iconBtnClose {\r\n    width: 24px;\r\n    height: 24px;\r\n    stroke: #99A2AD;\r\n}\r\n\r\n.iconBtnClose:hover {\r\n    stroke: #3A7AC4;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
