import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route, useHref } from 'react-router-dom';

import './arrivals.css';

import Card from '../card/Card';
import TechnoView from '../../Pages/TechnoView';


function ArrivalsSearch ( {parametr} ) {

const [posts, setPosts] = useState([]);

useEffect(() => {
    const fetchData = async () => {
        const result = await axios(
            parametr,
        );
        setPosts(result.data);
//        console.log(result.data);
    };
    fetchData();
}, []);

let item = posts[0];
//console.log(posts);
//console.log(item);
//console.log(Object.keys(item).length);


let DisplayData = '';
(item) ? (DisplayData = Object.values(item).map(
    (val) => {
//        console.log(val);
        return(            
            <Card parametr={val} />
        )}
)
) : (console.log());


    return (

        <section className='arrivals'>
            <div >
                <div className='contentArrivalsSearch'>

                    {DisplayData}

                </div>
            </div>
        </section>

    );
}


export default ArrivalsSearch;